import { COMFY_TOKEN } from "~/config";

function updateOptions(options: RequestInit = {}) {
  const update = { ...options };
  update.headers = {
    ...update.headers,
    Authorization: `Bearer ${COMFY_TOKEN}`,
  };
  return update;
}

export default function fetcher(input: RequestInfo | URL, init?: RequestInit) {
  return fetch(input, updateOptions(init));
}
