import { Container, Tabs, Title } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import type { MetaFunction } from "@remix-run/cloudflare";
import { ComfyUIApiClient } from "@stable-canvas/comfyui-client";
import { useEffect, useRef, useState } from "react";
import { Outlet, useMatch, useNavigate } from "@remix-run/react";
import { COMFY_URL, COMFY_TOKEN } from "~/config";
import fetcher from "~/libs/fetcher";
import { ClientOnly } from "remix-utils/client-only";
export const meta: MetaFunction = () => {
  return [
    { title: "Air Video Style" },
    {
      name: "description",
      content: "Make video style transfer easy and fun!",
    },
  ];
};

export default function Index() {
  const [client, setClient] = useState<ComfyUIApiClient | null>(null);
  const matches = useMatch("/:action");
  const navigate = useNavigate();

  useEffect(() => {
    if (!client) {
      const current = new ComfyUIApiClient({
        api_host: `${COMFY_URL.replaceAll(
          "https://",
          ""
        )}?token=${COMFY_TOKEN}`,
        ssl: true,
        fetch: fetcher,
      });
      current.connect();
      setClient(current);
      return () => {
        current.disconnect();
      };
    }
  }, []);

  return (
    <Container>
      <Tabs
        variant="outline"
        value={matches?.params.action}
        onChange={(e) => {
          navigate(`/${e}`);
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="vid2vid">Video to Video</Tabs.Tab>
          <Tabs.Tab value="stream">Stream</Tabs.Tab>
          <Tabs.Tab value="config">Config</Tabs.Tab>
        </Tabs.List>
        <ClientOnly>{() => <Outlet context={{ client }} />}</ClientOnly>
      </Tabs>
    </Container>
  );
}
